<template>
  <v-card
      min-height="280"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      :tile="textCard"
      id="card-table">
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat height="90vh" style="position: relative; display: flex; flex-direction: column;">
        <div class="close">
          <v-btn fab depressed small @click.stop="closePayArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">{{ `Архів оплат з ${dateStartFormatted}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal">
            <MainModal
                v-if="!organization.cabinet_only"
                :main="{ component: 'AccountPaymentView', title: 'Створити оплату' }"
                :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити'
                              }"
                :item="{
                         month: current_month,
                         person_id: flat.person_id,
                         flat_id: flat.id,
                         address: flat.address,
                         payer: flat.address,
                         person_hash: this.$route.params.person_hash,
                         legacy: true,
                         contract_name: flat.address
                         }"
                @updateItemModal="updateItemModal"
            />
            <MainModal v-if="payRow.address !== undefined && !organization.cabinet_only"
                       :main="{ component: 'AccountPaymentView', title: 'Змінити оплату' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign(
                           {month: current_month},
                                  payRow,
                          {person_hash: this.$route.params.person_hash, legacy: true})"
                       @updateItemModal="updateItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto;" :class="personal ? 'mt-3' : ''">
          <PayArchive
              :date_start="dateStart || '2020-01-01'"
              :modal="navigation"
              :person_hash="this.$route.params.person_hash ? this.$route.params.person_hash : person_hash"
              :updatePayArchive="updatePayArchive"
              :legacy="true"
              @afterPayArchiveUpdate="afterPayArchiveUpdate"
              @selectRow="selectRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="navigation_recalculation"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <div class="close">
          <v-btn fab depressed small @click.stop="closeRecalculationArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">{{
            `Перелік перерахунків з ${dateStartRecalculationFormatted}`
          }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal">
            <MainModal
                v-if="!organization.cabinet_only"
                :main="{ component: 'Recalculation', title: 'Створити перерахунок' }"
                :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити'
                              }"
                :item="{
                         month: current_month,
                         flat_id: flat.id,
                         is_legacy: true,
                         person_hash: flat.person_hash,
                         flat_name: flat.address,
                         date: getCurrentDate()
                         }"
                @updateItemModal="updateRecalculationItemModal"
            />
            <MainModal v-if="recalculationRow.service_id !== undefined && !organization.cabinet_only"
                       :main="{ component: 'Recalculation', title: 'Змінити перерахунок' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month, person_hash: flat.person_hash, flat_name: flat.address,}, recalculationRow)"
                       @updateItemModal="updateRecalculationItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto" :class="personal ? 'mt-3' : ''">
          <RecalculationArchive
              :date_start="dateStartRecalculation || '2020-01-01'"
              :modal="navigation_recalculation"
              :person_hash="this.$route.params.person_hash ? this.$route.params.person_hash : person_hash"
              :is_legacy="true"
              :updateRecalculationArchive="updateRecalculationArchive"
              @afterRecalculationArchiveUpdate="afterRecalculationArchiveUpdate"
              @selectRow="selectRecalculationRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="navigation_one_time"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <div class="close">
          <v-btn fab depressed small @click.stop="closeOneTimeArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">
          {{ `Перелік додаткових нарахувань з ${dateStartOneTimeFormatted}` }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal">
            <MainModal
                :main="{ component: 'OneTimeChargeByFlat', title: 'Створити додаткове нарахування' }"
                :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити'
                              }"
                :item="{
                         month: current_month,
                         person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : person_hash,
                         }"
                @updateItemModal="updateOneTimeItemModal"
            />
            <MainModal v-if="oneTimeRow.service_id !== undefined"
                       :main="{ component: 'OneTimeChargeByFlat', title: 'Змінити додаткове нарахування' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month}, oneTimeRow,
                       {person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : person_hash})"
                       @updateItemModal="updateOneTimeItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto" :class="personal ? 'mt-3' : ''">
          <OneTimeArchive
              :date_start="dateStartOneTime || '2020-01-01'"
              :modal="navigation_one_time"
              :person_hash="this.$route.params.person_hash ? this.$route.params.person_hash : person_hash"
              :updateOneTimeArchive="updateOneTimeArchive"
              @afterOneTimeArchiveUpdate="afterOneTimeUpdate"
              @selectRow="selectOneTimeRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-card-title class="px-0 py-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0" style="overflow: hidden">
      <div class="table" style="min-height: 60vh; display: flex; flex-direction: column;"
           :class="{
              'sm-table': $vuetify.breakpoint.sm,
              'xs-table': $vuetify.breakpoint.xs,
            }"
      >
        <div class="table-header" style="flex: 0">
          <div class="table-row">
            <div class="table-col">Послуга</div>
            <div class="table-col">Об’єм</div>
            <div class="table-col">Тариф</div>
            <div class="table-col" style="line-height: 14px">Сальдо <br> на початок</div>
            <div class="table-col">Нараховано</div>
            <div class="table-col">Перерахунок</div>
            <div class="table-col">Оплата</div>
            <div class="table-col">ПДВ</div>
            <div class="table-col">Всього</div>
            <div class="table-col" style="line-height: 14px">Сальдо <br> на кінець</div>
            <div class="table-col hidden"></div>
          </div>
        </div>
        <div class="wrapper" style="max-height: 78vh; flex: 1; overflow-y: overlay;">
          <div v-for="(m_item, m_index) in archive_items" :key="m_index"
               class="expand-wrapper-row">
            <div class="expand-header level-0">
              <div class="table-row">
                <div class="table-col">
                  <div>
                    <v-icon size="22" color="grey darken-3" class="mr-3 expand-icon"
                            :id="`icon_${m_index}`"
                            @click="expand_row(`exp-body_${m_index}`)">
                      {{ 'mdi-chevron-down' }}
                    </v-icon>
                  </div>
                  <div class="text-capitalize">
                    {{ m_item.month | formatDate('MMMM YYYY') }}
                  </div>
                </div>
                <div class="table-col"></div>
                <div class="table-col"></div>
                <div class="table-col"
                     :class="[
                          {'error--text': m_item.start_balance > 0},
                          {'success--text': m_item.start_balance < 0}
                      ]"
                >
                  {{ m_item.start_balance | formatToFixed | formatNumber }}
                </div>
                <div class="table-col">
                  {{ m_item.charge | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" @click="openRecalculationArchive(m_item.month)">
                  {{ m_item.recalculation | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" @click="openPayArchive(m_item.month)">
                  {{ m_item.pay | formatToFixed | formatNumber }}
                </div>
                <div class="table-col">
                  {{ m_item.tax | formatToFixed | formatNumber }}
                </div>
                <div class="table-col">
                  {{ m_item.to_pay | formatToFixed | formatNumber }}
                </div>
                <div class="table-col"
                     :class="[
                          {'error--text': m_item.end_balance > 0},
                          {'success--text': m_item.end_balance < 0}
                      ]"
                >
                  {{ m_item.end_balance | formatToFixed | formatNumber }}
                </div>
              </div>
            </div>
            <div class="expand-body"
                 :class="`exp-body_${m_index} ${m_index === archive_items.length - 1 ? 'show' : ''}`">
              <div v-for="(c_item, c_index) in m_item.details" :key="`${m_index}_${c_index}`"
                   class="expand-wrapper-row">
                <div class="expand-header table-row level-1">
                  <div class="table-col">
                    <div>
                      <v-icon size="22" color="grey darken-3" class="ml-6 mr-3 expand-icon"
                              :id="`icon_${m_index}_${c_index}`"
                              @click="expand_row(`exp-body_${m_index}_${c_index}`)">
                        {{ 'mdi-chevron-down' }}
                      </v-icon>
                    </div>
                    <div class="text-capitalize">
                      {{ c_item.contract_name }}
                    </div>
                  </div>
                  <div class="table-col"></div>
                  <div class="table-col"></div>
                  <div class="table-col"
                       :class="[
                          {'error--text': c_item.start_balance > 0},
                          {'success--text': c_item.start_balance < 0}
                      ]"
                  >
                    {{ c_item.start_balance | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col">
                    {{ c_item.charge | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" @click="openRecalculationArchive(c_item.month)">
                    {{ c_item.recalculation | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" @click="openPayArchive(c_item.month)">
                    {{ c_item.pay | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col">
                    {{ c_item.tax | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col">
                    {{ c_item.to_pay | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col"
                       :class="[
                          {'error--text': c_item.end_balance > 0},
                          {'success--text': c_item.end_balance < 0}
                      ]"
                  >
                    {{ c_item.end_balance | formatToFixed | formatNumber }}
                  </div>
                </div>
                <div class="expand-body" :class="`exp-body_${m_index}_${c_index}`">
                  <div v-for="(item, index) in c_item.details" :key="`${m_index}_${c_index}_${index}`"
                       class="table-row">
                    <div class="table-col">
                      <div class="icon" style="display: flex; justify-content: center; align-items: center">
                        <v-icon class="ml-15 mr-3" size="22" :color="getAccountIcon(item.icon, 'color')">
                          {{ getAccountIcon(item.icon, 'icon') }}
                        </v-icon>
                      </div>
                      <div>{{ item.service_name }}</div>
                    </div>
                    <div class="table-col">{{ item.square }}</div>
                    <div class="table-col">{{ item.tariff | formatNumber('0.0000') }}</div>
                    <div class="table-col"
                         :class="[
                          {'error--text': item.start_balance > 0},
                          {'success--text': item.start_balance < 0}
                      ]"
                    >
                      {{ item.start_balance | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col">
                      {{ item.charge | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col" @click="openRecalculationArchive(item.month)">
                      {{ item.recalculation | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col" @click="openPayArchive(item.month)">
                      {{ item.pay | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col">
                      {{ item.tax | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col">
                      {{ item.to_pay | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col"
                         :class="[
                          {'error--text': item.end_balance > 0},
                          {'success--text': item.end_balance < 0}
                      ]"
                    >
                      {{ item.end_balance | formatToFixed | formatNumber }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PayArchive from "@/components/PayArchive";
import RecalculationArchive from "@/components/RecalculationArchive";
import OneTimeArchive from "@/components/OneTimeArchive";
import {mapActions, mapGetters} from 'vuex'
import {GET_ARCHIVE_LEGACY} from "@/store/actions/flat_service";
import {getAccountIcon, getCurrentDate} from "@/utils/icons";
import {formatDate} from "@/filters"
import {GET_FLAT} from "@/store/actions/flat";

export default {
  name: "HWL_Archive",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Архів розрахунків по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
  },
  components: {
    PayArchive,
    RecalculationArchive,
    OneTimeArchive,
    MainModal: () => import('@/components/modal/MainModal')
  },
  computed: {
    ...mapGetters({
      archive_items: 'getFlatArchive',
      current_month: 'getCurrentMonth',
      flat_: 'getLegacyFlat',
      flat_current: 'getCurrentUserFlat',
      organization: 'getCurrentOrganization',
      bottom_width: 'getBottomSheetWidth'
    }),
    flat() {
      return this.personal ? this.flat_current : this.flat_
    },
    dateStartFormatted() {
      return formatDate(this.dateStart)
    },
    dateStartRecalculationFormatted() {
      return formatDate(this.dateStartRecalculation)
    },
    dateStartOneTimeFormatted() {
      return formatDate(this.dateStartOneTime)
    }
  },
  data() {
    return {
      navigation: false,
      navigation_recalculation: false,
      navigation_one_time: false,
      open: [],
      services: ['Утримання будинку та прибудинкової території', 'Вивіз ТПВ', 'Електроенергія'],
      service: null,
      dateStart: null,
      dateStartRecalculation: null,
      dateStartOneTime: null,
      dateEnd: null,
      updatePayArchive: false,
      updateRecalculationArchive: false,
      updateOneTimeArchive: false,
      payRow: {},
      recalculationRow: {},
      oneTimeRow: {}
    }
  },
  methods: {
    ...mapActions({
      fetchArchive: GET_ARCHIVE_LEGACY,
      fetchOne: GET_FLAT,
    }),
    getCurrentDate,
    getAccountIcon,
    expand_row: function (row_id) {
      const idx = row_id.replace('exp-body', '').split('_').filter(i => i !== '')
      let idx_concat = ''
      idx.forEach(item => {
        idx_concat += idx_concat === '' ? item : '_' + item
        const idx_current = `exp-body_${idx_concat}`

        if (row_id === idx_current) {
          const icon = document.getElementById(`icon_${idx_concat}`)
          const items = Array.from(document.getElementsByClassName(`exp-body_${idx_concat}`))
          items.forEach(i => {
            i.classList.toggle('show')
          })
          if (icon.classList.contains('mdi-chevron-down')) {
            icon.classList.remove('mdi-chevron-down')
            icon.classList.add('mdi-chevron-up')
          } else {
            icon.classList.remove('mdi-chevron-up')
            icon.classList.add('mdi-chevron-down')
          }
        }
      })
      const row = document.getElementsByClassName(row_id)[0]
      row.scrollIntoView({block: "start", behavior: "smooth"})
      // const row = document.getElementById(row_id)
      // const prev_row_index = +row_id.substring(row_id.lastIndexOf('-')+1)
      // const table_body = row.getElementsByClassName('table-body')[0]
      // table_body.classList.toggle('show')
      // if (this.open.includes(row_id)) {
      //   const open_id = this.open.indexOf(row_id)
      //   this.open.splice(open_id, 1)
      //   if (prev_row_index >= 0) {
      //     let prev_row = document.getElementById(`exp-row-${prev_row_index}`)
      //     prev_row.scrollIntoView({block: "start", behavior: "smooth"})
      //   }
      // } else {
      //   this.open.push(row_id)
      //   row.scrollIntoView({block: "start", behavior: "smooth"})
      //   // window.scrollTop(row.offset().top - row.offset().top/5);
      // }
    },
    openPayArchive(month) {
      this.dateStart = month
      this.navigation = true
    },
    openRecalculationArchive(month) {
      this.dateStartRecalculation = month
      this.navigation_recalculation = true
    },
    openOneTimeArchive(month) {
      this.dateStartOneTime = month
      this.navigation_one_time = true
    },
    selectRow(payload) {
      this.payRow = payload
    },
    selectRecalculationRow(payload) {
      this.recalculationRow = payload
    },
    selectOneTimeRow(payload) {
      this.oneTimeRow = payload
    },
    closePayArchive() {
      this.payRow = {}
      this.navigation = false
    },
    closeRecalculationArchive() {
      this.recalculationRow = {}
      this.navigation_recalculation = false
    },
    closeOneTimeArchive() {
      this.oneTimeRow = {}
      this.navigation_one_time = false
    },
    updateItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updatePayArchive = true
        }
      }
    },
    updateRecalculationItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updateRecalculationArchive = true
        }
      }
    },
    updateOneTimeItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updateOneTimeArchive = true
        }
      }
    },
    afterPayArchiveUpdate() {
      this.updatePayArchive = false
      this.payRow = {}
    },
    afterRecalculationArchiveUpdate() {
      this.updateRecalculationArchive = false
      this.recalculationRow = {}
    },
    afterOneTimeUpdate() {
      this.updateOneTimeArchive = false
      this.oneTimeRow = {}
    }
  },
  mounted() {
    if (this.$route.params.person_hash) {
      this.fetchArchive(this.$route.params.person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchArchive(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #e6e6e6;
$border-color-mini: #d9d9d9;

.close {
  position: absolute;
  right: -8px;
  top: -9px;
}

:deep(.v-input__control) {
  height: 30px !important;
}

:deep(.v-text-field.v-text-field--solo .v-input__control) {
  min-height: 34px;
}

.buttons {
  :deep(.v-toolbar__content) {
    padding-left: 0;
    padding-right: 0;
  }
}

.expand-icon:focus:after {
  opacity: 0 !important;
}

.expand-icon:hover {
  color: green !important;
}

.table {
  min-width: 1000px;

  .table-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    flex: 1 1;
    border-bottom: 1px solid $border-color;
    //border-bottom: 1px solid $border-color;

    .hidden {
      visibility: hidden;
    }

    .table-col {
      flex: 0 1 100px;
      flex-wrap: nowrap;
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $border-color;
      font-size: 13px;
      font-weight: normal;
      color: #5a5a5a;
    }

    .table-col:nth-child(1) {
      flex: 0 1 300px;
      min-width: 300px;
      justify-content: flex-start !important;
    }

    .table-col {
      .table-col-group {
        width: 100%;

        .table-col {
          border: none !important;
          justify-content: center !important;
        }
      }
    }
  }

  .expand-header {
    .table-row {
      .table-col {
        padding: 3px !important;
      }
    }
  }

  .expand-body {
    display: none;
  }

  .expand-body.show {
    display: block;
  }

  .expand-header.level-0 {
    .table-row {
      .table-col {
        background-color: #f5f5f5;
      }
    }
  }

  .expand-header.level-1 {
    .table-col {
      background-color: #f8f8f8;
    }
  }

  .table-header {
    .table-row {
      font-size: 12px;
      font-weight: bold;

      .table-col {
        text-align: center;
        border: none;
        background-color: #f9f9f9;
        //border-bottom: 1px solid $border-color;

        &:nth-child(1) {
          padding: 7px 7px 7px 21px !important;
        }
      }
    }
  }

  .table-body.show {
    display: block;
    max-height: 100%;
    transition: all .3s linear;

    .table-row {
      &:hover .table-col {
        background-color: #f8f8f8;
      }

      .table-col {
        background-color: #f8f8f833;
        border-right: .5px solid $border-color;
        transition: 20ms background-color linear;
      }

      &:last-child {
        .table-col {
          border-bottom: .5px solid $border-color;
        }
      }
    }
  }

  .table-body {
    display: none;
    max-height: 0;

    .table-row {
      font-size: 0.85rem;


      .table-col:nth-child(1) {
        padding: 7px !important;
        line-height: 16px;
      }

      .table-col:nth-child(1) .icon {
        margin-left: 34px;
      }
    }
  }

  .expand-row {
    font-size: 13px;
    font-weight: normal;
    border-bottom: none !important;
    color: #5a5a5a;

    &:hover .table-col {
      background-color: #f8f8f8;
    }

    .table-col {
      border-right: .5px solid $border-color;
      border-bottom: .5px solid $border-color;
      //border-top: .5px solid $border-color;
      padding: 3px !important;

      &:nth-child(1) {
        padding: 3px 3px 3px 21px !important;
      }
    }

    &:nth-child(1) .table-col {

    }

    &:last-child .table-col {
      border-top: .5px solid $border-color;
    }
  }

  .expand-row.active {
    font-weight: 500;
  }

}


//@media only screen and (min-width : 330px) and (max-width : 1152px) {
//  .table {
//    .table-row {
//      .hidden {
//        visibility: visible;
//      }
//
//      .table-col {
//        flex: 1 1 25%;
//        padding: 6px !important;
//        border-bottom: 1px solid $border-color-mini;
//      }
//
//      .table-col:nth-child(1) {
//        flex: 1 1 50%;
//      }
//      .table-col:nth-child(2) {
//        justify-content: center !important;
//      }
//      .table-col:nth-child(3) {
//        justify-content: flex-end !important;
//      }
//      .table-col:nth-child(4) {
//        justify-content: flex-start !important;
//      }
//      .table-col:nth-child(5) {
//        justify-content: center !important;
//      }
//      .table-col:nth-child(6) {
//        justify-content: center !important;
//      }
//      .table-col:nth-child(7) {
//        justify-content: flex-end !important;
//      }
//      .table-col:nth-child(8) {
//        justify-content: flex-start !important;
//      }
//      .table-col:nth-child(9) {
//        justify-content: center !important;
//      }
//      .table-col:nth-child(10) {
//        justify-content: center !important;
//      }
//    }
//    .table-header {
//      display: none;
//    }
//    .expand-row, .table-row {
//      .table-col {
//        display: flex;
//        flex-wrap: wrap;
//
//        &:before {
//          width: 100%;
//          margin-bottom: 6px;
//          color: #b5b5b5;
//          font-weight: 300;
//          display: block;
//        }
//      }
//
//      .table-col:nth-child(1){
//        display: flex;
//        flex-wrap: wrap;
//
//        &:before {
//          content: 'Послуга';
//        }
//      }
//      .table-col:nth-child(2):before {
//        content: '';
//        text-align: center;
//      }
//      .table-col:nth-child(3):before {
//        content: '';
//        text-align: right;
//      }
//      .table-col:nth-child(4):before {
//        content: 'Сальдо на поч.';
//      }
//      .table-col:nth-child(5):before {
//        content: 'Нараховано';
//        text-align: center;
//      }
//      .table-col:nth-child(6):before {
//        content: 'Перерахунок';
//        text-align: center;
//      }
//      .table-col:nth-child(7):before {
//        content: 'Пільга';
//        text-align: right;
//      }
//      .table-col:nth-child(8):before {
//        content: 'Оплата';
//      }
//      .table-col:nth-child(9):before {
//        content: 'Субсидія';
//        text-align: center;
//      }
//      .table-col:nth-child(10):before {
//        content: 'Сальдо на кін.';
//        text-align: center;
//      }
//    }
//  }
//}
</style>
